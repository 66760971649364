<template>
  <div style="margin: 20px">
    <div class="bg-white p-12">
      <p class="text-2xl font-bold mb-4 title" style="color: #ff8900">
        {{ isAdd ? "New " : "Edit" }} Activity
      </p>
      <el-form
        label-position="top"
        :rules="rules"
        ref="activityInfo"
        :model="activityInfo"
      >
        <!-- <el-row :gutter="24">
          <el-col :span="12"> -->
        <el-form-item label="Status" prop="status">
          <el-radio-group v-model="activityInfo.status">
            <el-radio label="In Plan"
              >In Plan

              <el-tooltip
                class="item"
                effect="dark"
                content="the activity you decide to do in the near future"
                placement="top-start"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-radio>

            <el-radio label="In Evaluation"
              >In Evaluation

              <el-tooltip
                class="item"
                effect="dark"
                content="the activity that is an option but you are still evaluating it before you decide to go"
                placement="top-start"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-radio>

            <el-radio label="Ongoing"
              >Ongoing

              <el-tooltip
                class="item"
                effect="dark"
                content="the activity that you are currently working on"
                placement="top-start"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-radio>
            <el-radio label="Paused"
              >Paused
              <el-tooltip
                class="item"
                effect="dark"
                content="the activity that you decide to strategically park for the moment"
                placement="top-start"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-radio>
            <el-radio label="Completed"
              >Completed
              <el-tooltip
                class="item"
                effect="dark"
                content="the activity that you have finished"
                placement="top-start"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-radio>
            <el-radio label="Cancelled"
              >Cancelled
              <el-tooltip
                class="item"
                effect="dark"
                content="the activity that you decide to quit and not to resume"
                placement="top-start"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- </el-col> -->
        <!-- </el-row> -->
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="Activity Title" prop="type">
              <el-input
                v-model="activityInfo.type"
                placeholder="Please input activity title"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="Start Time" prop="start_time">
              <el-date-picker
                v-model="activityInfo.start_time"
                type="month"
                value-format="timestamp"
                placeholder="Please select start time"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="End Time" prop="end_time">
              <el-date-picker
                v-model="activityInfo.end_time"
                type="month"
                value-format="timestamp"
                placeholder="Please select end time"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="Hours per Week" prop="hours_peer_week">
              <el-input
                v-model="activityInfo.hours_peer_week"
                placeholder="Please input Hours per Week"
                type="number"
                min="0"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="Position/Role (Up to 50 characters)" prop="position">
              <el-input
                v-model="activityInfo.position"
                placeholder="Please input Position/Role"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          label="Organization (Up to 100 characters)"
          prop="org"
        >
          <el-input
            v-model="activityInfo.org"
            placeholder="For events organized by third parties, input the hosting organization; for self-operated activities, enter the executing entity."
          ></el-input>
        </el-form-item>

        <el-form-item label="Description (Up to 150 characters)" prop="desc">
          <el-input
            v-model="activityInfo.desc"
            placeholder="Provide an overview of this activity, including your achievements and any accolades or recognition you may have received."
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="Attachment" prop="">
          <div style="display: flex">
            <span v-if="attachmentUrl && attachmentUrl!=''" style="margin-right: 20px">{{ attachmentUrl }}</span>
            <el-button size="small" type="primary" @click="clickShowAttachmentDialog">
              {{ attachmentUrl && attachmentUrl != '' ? 'Edit' : ' Add' }} Attachment
            </el-button>
          </div>
        </el-form-item> -->
      </el-form>
      <el-button type="primary" @click="save" style="margin-top: 20px">{{
        isAdd ? "Save" : "Save"
      }}</el-button>
    </div>

    <el-dialog :visible.sync="showAttachmentDialog" title="Activity Attachment">
      <el-form>
        <el-form-item label="Upload Method:">
          <el-select v-model="uploadType">
            <el-option label="Local Upload" value="local"></el-option>
            <el-option label="Remote Upload" value="url"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Remote Url:" v-if="uploadType === 'url'">
          <div class="flex">
            <el-input v-model="attachmentUrl" class="w-1/2" />
          </div>
        </el-form-item>
        <el-upload
          class="upload-demo mt-2"
          ref="upload"
          action="#"
          :limit="1"
          :before-upload="beforeAttachUpload"
          v-if="uploadType === 'local'"
        >
          <el-button slot="trigger" size="small" type="primary"
            >Select File
          </el-button>
        </el-upload>
      </el-form>
      <el-button
        type="primary"
        @click="showAttachmentDialog = false"
        style="margin-top: 20px"
        >Confirm</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { getMyActivities, getSingaporeQiniuToken, updateActivity } from "../../api/eae";
import { getUserId } from "../../utils/store";
import { formatDate } from "../../utils/date";
import Activity from "../../components/Activity.vue";
import * as qiniu from "qiniu-js";

export default {
  name: "index",
  data() {
    return {
      uploadType: "url",
      showAttachmentDialog: false,
      attachmentUrl: "",
      dataList: [],
      activityInfo: {
        id: "",
        student_id: getUserId(),
        type: "",
        position: "",
        org: "",
        desc: "",
        hours_peer_week: "",
        start_time: "",
        end_time: "",
        status: "",
      },
      rules: {
        desc: [
          {
            required: true,
            message: "Please input activity description.",
            trigger: "blur",
          },
          {
            min: 0,
            max: 150,
            message: "Description characters at maximum 150",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Please input activity title",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "Please select status",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "Please input your position/role.",
            trigger: "blur",
          },
          {
            min: 0,
            max: 50,
            message: "Position characters at maximum 50",
            trigger: "change",
          },
        ],
        org: [
          {
            required: true,
            message: "Please input the organization.",
            trigger: "blur",
          },
          {
            min: 0,
            max: 100,
            message: "Organization characters at maximum 100",
            trigger: "change",
          },
        ],
        hours_peer_week: [
          {
            required: true,
            message: "Please input Hours per Week",
            trigger: "blur",
          },
        ],
        // start_time: [
        //   {
        //     required: true,
        //     message: "Please select start time",
        //     trigger: "blur",
        //   },
        // ],
        // end_time: [
        //   {
        //     required: true,
        //     message: "Please select end time",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  components: {
    Activity,
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    isAdd() {
      return !this.$route.query.id;
    },
  },
  methods: {
    clickShowAttachmentDialog() {
      this.attachmentUrl = "";
      this.showAttachmentDialog = true;
    },
    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.attachmentUrl =
              "https://ap.learningfirst.tech/" + res.key;
            this.showAttachmentDialog = false;
          },
        };
        this.observable.subscribe(observer);
      } else {
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getSingaporeQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },

    fetchData() {
      getMyActivities(getUserId()).then((res) => {
        let result = res.data.data;
        this.dataList = result.map((item) => {
          if (item.start_time) {
            item.show_start_time = formatDate(
              new Date(item.start_time * 1000),
              "yyyy-MM"
            );
          } else {
            item.show_start_time = "";
          }
          if (item.end_time) {
            item.show_end_time = formatDate(
              new Date(item.end_time * 1000),
              "yyyy-MM"
            );
          } else {
            item.show_end_time = "";
          }
          return item;
        });
        if (!this.isAdd) {
          const currentActivity = this.dataList.find(
            (item) => item._id.$id === this.$route.query.id
          );
          this.activityInfo = {
            ...currentActivity,
            id: this.$route.query.id,
            start_time: currentActivity.start_time * 1000,
            end_time: currentActivity.end_time * 1000,
          };
          this.attachmentUrl = this.activityInfo.attachmentUrl;
        }
      });
    },
    save() {
      let start_time = ''
      let end_time = ''
      if(this.activityInfo.start_time) {
        start_time = this.activityInfo.start_time / 1000
      }
      if(this.activityInfo.end_time) {
        end_time = this.activityInfo.end_time / 1000
      }
      // if (this.activityInfo.end_time <= this.activityInfo.start_time) {
      //   this.$message.error(
      //     "The end time must not be early than the start time"
      //   );
      //   return;
      // }
      const params = {
        id: this.activityInfo.id,
        student_id: this.activityInfo.student_id,
        type: this.activityInfo.type,
        position: this.activityInfo.position,
        org: this.activityInfo.org,
        desc: this.activityInfo.desc,
        hours_peer_week: this.activityInfo.hours_peer_week,
        start_time: start_time,
        end_time: end_time,
        attachmentUrl: this.attachmentUrl,
        status: this.activityInfo.status,
      };

      this.$refs["activityInfo"].validate((valid) => {
        if (valid) {
          updateActivity(params).then((res) => {
            this.$message.success("Saved Successfully");
            this.$router.push({
              path: "/home/activities",
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
